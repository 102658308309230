import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from '../views/LoginView.vue'

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginView,
    },
    {
        path: '/time-tracking',
        name: 'TimeTracking',
        // route level code-splitting
        // this generates a separate chunk (time-tracking.[hash].js) for this route
        // which is lazy-loaded when the route visited.
        component: () => import(/* webpackChunkName: "time-tracking" */ '../views/TimeTrackingView.vue'),
        props: true,
    },
];

export default new VueRouter({routes});
