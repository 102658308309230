<template>
    <div class="container">
        <div id="app">
            <router-view />
        </div>
    </div>
</template>

<script>
import dayjs from 'dayjs';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

export default {
    name: 'App',
    computed: {
        /*
        myProps() {
          if (this.$route.name === 'Login') {
            return {
              checkLoginUrl: localStorage.checkLoginUrl,
              checkLoginToken: localStorage.checkLoginToken
            }
          }
          if (this.$route.name === 'TimeTracking') {
            return {
              timeTrackingUrl: localStorage.timeTrackingUrl,
              timeTrackingToken: localStorage.timeTrackingToken
            }
          }
          return 0;
        }
         */
    },
    created() {
        if (this.$workbox) {
            this.$workbox.addEventListener('waiting', () => {
                this.showUpdateUI = true;
            });
        }
        
        // if an uid and date is present in storage from this day automatically login
        if (this.$offlineStorage.get('uid') !== ''
            && dayjs(this.$offlineStorage.get('date')).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
            this.$router.push({name: 'TimeTracking', params: {uid: this.$offlineStorage.get('uid')}})
        }
        
        // If no uid in the request or no user found by uid a 401 is returned.
        axios.interceptors.response.use(response => response, error => {
            if (error.response.status === 401) {
                this.$router.push({name: 'Login'});
            }
            
            return error.response;
        });
    },
    async accept() {
        this.showUpdateUI = false;
        await this.$workbox.messageSW({type: 'SKIP_WAITING'});
    }
}
</script>

<style>
#app {
    font-family: 'Roboto Mono', monospace;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    padding: 1.5rem;
}
</style>
