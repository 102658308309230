<template>
    <div>
        <vue-loading :active.sync="this.isLoading" :loader="'bars'" :is-full-page="true" :color="'#e3000b'" />
        <div class="alert alert-danger alert-dismissible row" role="alert" v-if="errors.length > 0">
            <div class="col-auto g-0">
                <fa-icon :icon="['fas', 'exclamation-triangle']" />
            </div>
            <div class="col text-start">
                <template v-for="(message, index) in errors">
                    {{ message }}
                    <br v-if="index < errors.length - 1" :key="index">
                </template>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        <div>
            <h3>Anmelden</h3>
            <div class="row">
                <div class="col-12 mt-3">
                    <label>Anmeldename</label>
                    <input type="text" class="form-control form-control-lg" v-model="login"
                           :class="{'is-invalid': showInvalid && login === ''}" required
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <label>Passwort</label>
                    <input type="password" class="form-control form-control-lg" v-model="password"
                           :class="{'is-invalid': showInvalid && password === ''}" required
                    >
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="d-grid gap-2">
                        <button type="button" class="btn btn-primary btn-lg" @click="checkLogin">Anmelden</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import urls from '../urls';
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'LoginPage',
    components: {VueLoading},
    props: {},
    data() {
        return {
            login: '',
            password: '',
            errors: [],
            showInvalid: false,
            isLoading: false,
        }
    },
    methods: {
        checkLogin() {
            this.errors = [];
            this.showInvalid = true;
            
            if (this.login === '') {
                this.errors.push('Der Anmeldename darf nicht leer sein!');
            }
            
            if (this.password === '') {
                this.errors.push('Das Passwort darf nicht leer sein!');
            }
            
            // when no error until now we will post the data
            if (this.errors.length > 0) {
                return;
            }
            
            this.isLoading = true;
            axios.post(urls.login, {'login-name': this.login, password: this.password})
                 .then(response => {
                     if (response.data.uid) {
                         this.$offlineStorage.set('uid', response.data.uid);
                         this.$offlineStorage.set('date', response.data.date);
                         this.$offlineStorage.set('costCentre', response.data.costCentre);
                         this.$offlineStorage.set('workingTime', response.data.workingTime);
                         this.$offlineStorage.set('breakTime', response.data.breakTime);
                         this.$offlineStorage.set('timeState', response.data.timeState);
                         this.$router.push({name: 'TimeTracking', params: {uid: response.data.uid}})
                     } else {
                         this.errors.push('Falsches Passwort oder Benutzername!')
                     }
                 })
                 .catch(() => {
                     this.errors.push('Keine Verbindung zum Anmeldeserver!');
                 })
                 .finally(() => {
                     this.isLoading = false;
                 });
        }
    }
}
</script>